.Sidebar {
    margin: 3rem 20px 1rem;
}

.Sidebar .main-logo {
    display: flex;
    flex-direction: column;
    margin: 0 6px 20px;
}

.Sidebar .dashed-line {
    border: none;
    border-top: 1px dashed white;
    width: 100%;
    margin: 30px 0;
    border-spacing: 6px;
    border-collapse: separate; 
}

.Sidebar .menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-top: 18px;
}

.menu-icon {
    margin: 0 8px 0 10px;
}

.menu-item .menu {
    flex: 1;
    color: white;
    font-size: 16px;
    /* font-weight: 600; */
    line-height: normal;
}

.menu-icon .icon {
    margin-bottom: -4px;
}

.menu-item .menu-dropDown {
    transition: transform 0.3s ease;
}

.active {
    background: var(--activeMenuBg);
    border-radius: 9px;
    margin-left: -12px;
    padding: 11px 14px 12px;
}


/* Hover Effects */

.menu-item:hover .menu-dropDown {
    transform: rotate(90deg);
}

