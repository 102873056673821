.Wallet {
    width: 289px;
    height: 151px;
    background: var(--walletBladeBG);
    border-radius: 12px;
    position: relative;
}

.bg-elements {
    position: absolute;
    top: 0;
    left: 0;
}

.bg-elements img:nth-child(3) {
    position: absolute;
    top: 83px;
    left: 216px;
}

.bg-elements img:nth-child(4) {
    position: absolute;
    top: 1px;
    left: 110px;
}

.bg-elements img:nth-child(1) {
    position: absolute;
    top: 0px;
    left: 176px;
}

.bg-elements img:nth-child(2) {
    position: absolute;
    top: -6px;
    left: 164px;
}

.Wallet .w-top {
    padding: 22px 22px 22px 22px;
}

.w-top .w-Title {
    font-family: 'Nunito', sans-serif;
    color: #cdcdcd;
    font-size: 14px;
    font-weight: 400;
}

.w-top .amount {
    font-family: 'Nunito', sans-serif;
    color: white;
    font-weight: 600;
    font-size: 20px;
}

.w-top .amount span {
    font-family: Sora;
    color: white;
    font-weight: 600;
    font-size: 20px;
}


.amount-img { /* Visible */
    margin-left: 20px;
    margin-bottom: -4px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    z-index: 99;
}

.w-top .w-ctas {
    margin-top: 16px;
    display: flex;
    gap: 17px;
}

.amount-img {
    position: relative;
  }
  
.amount-img::after {
    content: attr(title);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #121826;
    color: #ffffff;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
}
  
  .amount-img:hover::after {
    opacity: 1;
  }
  

.hidden {
  display: none;
}



