:root {
  --yellow: linear-gradient(180deg, #F8D49A -146.42%, #FAD79D -46.42%);
  --scafoldBg: #121826;
  --mobileMenuBg: #032282;
  --activeMenuBg: #192749;
  --btnBG: #ffffff4d;
  --walletBladeBG: #032282;
  --bladeBoxBG: white;
  --bladeBG: #f6faff;
  --BladeBtn: #eaf4ff;
  --successBG: #18a201;
  --failBG: #ff0000;
  --sendBG: #032282;
  --cashOutBG: #f4be38;
  --utilBG: #ede1ff;
  --dashboardBG: #f8f9fb;
  --headerText: #242424;
  --bladeHeaderText: #4f64a7;
}

.App {
  background: var(--scafoldBg);
  height: 100vh;
  display: grid;
  font-family: 'Nunito', sans-serif;
  overflow: hidden;
  grid-template-columns: 16.6rem auto;
}

.add-cta {
  font-size: 14px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  color: white;
  width: 112px;
  height: 40px;
  border-radius: 7px;
  background: var(--btnBG);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--BladeBtn);
  border-radius: 6px;
  width: 82px;
  height: 30px;
  color: var(--walletBladeBG);
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  gap: 6px;
  cursor: pointer;
}

.date-title {
  font-size: 12px;
  font-family: 'Nunito', sans-serif;
  color: var(--bladeHeaderText);
  font-weight: 400;
}

.cost-style {
  color: var(--mobileMenuBg);
  font-size: 14px;
  font-weight: 600;
  font-family: Sora, 'Nunito';
}

.title {
  margin-top: 6px;
  color: var(--bladeHeaderText);
  font-size: 14px;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
}