.Header {
    display: flex;
    background: white;
    height: 111px;
    border-radius: 30px;
    justify-content: space-between;
}

.Header .h-title {
    margin: 57px 22px 0;
    color: var(--headerText);
    font-size: 24px;
    font-weight: 600;
}

.h-rightSide {
    display: flex;
    margin-top: 46px;
    margin-right: 30px;
    gap: 16px;
}

.h-rightSide .notifi {
    background: #ecf1ff;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.numb {
    position: relative;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: var(--failBG);
    z-index: 1;
    left: 7px;
    bottom: 9px;
}

.h-icon {
    position: absolute;
    align-items: center;
    justify-content: center;
    z-index: 0;
}

.h-user {
    display: flex;
    gap: 11px;
}

.user-details .mail-dropdown {
    display: flex;
    gap: 11px;
}

.user-details .user-name {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    line-height: normal;
}

.user-details .user-email {
    font-size: 10px;
    color: #818181;
    font-weight: 400;
}

.mail-dropdown .expand-icon {
    margin-top: -3px;
}
